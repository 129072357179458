var app = {};
app.init = function () {
  app.heroSlider();
  app.tour();
  app.video();
  app.videopreview();
  app.galleryModal();
  app.dropdown();
  app.hamburger();
};

// Home Sliders
app.heroSlider = function () {
  if ($(".slider").length) {
    var slideIndex = 0;
    var slides = document.getElementsByClassName("hero-slides");

    function showSlides(n) {
      if (n >= slides.length) {
        slideIndex = 0;
      }
      if (n < 0) {
        slideIndex = slides.length - 1;
      }
      for (var i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slides[slideIndex].style.display = "block";
    }

    function plusSlides(n) {
      slideIndex += n;
      showSlides(slideIndex);
    }

    function autoAdvance() {
      plusSlides(1);
    }

    var timerId = setInterval(autoAdvance, 9000);

    $(".slider-prev").click(function () {
      plusSlides(-1);
      clearInterval(timerId);
      timerId = setInterval(autoAdvance, 9000);
    });

    $(".slider-next").click(function () {
      plusSlides(1);
      clearInterval(timerId);
      timerId = setInterval(autoAdvance, 9000);
    });

    $(".pause-slideshow").on("click", function () {
      clearInterval(timerId);
      $(this).hide();
      $(".play-slideshow").show();
    });

    $(".play-slideshow").on("click", function () {
      timerId = setInterval(autoAdvance, 9000);
      $(this).hide();
      $(".pause-slideshow").show();
    });

    // Initialize the slider
    showSlides(slideIndex);

    // Hide buttons if only one slide exists
    if (slides.length < 2) {
      $(".slider-prev, .slider-next, .pause-slideshow").hide();
    }
  }
};


app.tour = function () {
  const tourContainer = $("#tourDates");

  $.ajax({
    type: "GET",
    url: "https://tour.sarahmclachlan.com/api/liveevents/sarahmclachlan",
    success: function (result) {
      parseData(result);
      
    },
  });

  function parseData(responseData) {
    const events = responseData;
    // console.log(events);
    let items = "";

    if (events) {
      for (let i = 0; i < events.length; i++) {
        let event = events[i].LiveEvent;

        // Get the Date and put it in a <div>
        let date = formatDate(
          new Date(event.EventDate.replace(/-/g, "/").replace(/T.+/, ""))
        );
        let dateWrap = $("<div>");
        dateWrap.addClass("date");
        dateWrap.append(date);

        // Get the Venue and Location Details and put it in a <div>
        //venue
        let venue = event.Venue;
        let venueLink = $("<a>");
        venueLink.attr("href", event.VenueUrl);
        venueLink.attr("target", "_blank");
        venueLink.addClass("location");
        venueLink.append(venue);
        //city
        let city = event.City;
        let region = event.Region;
        let state = event.State;
        let country = event.Country;
        let note = event.EventListNote;
        let cityWrap = $('<span class="city">');
        cityWrap.append(city, `, `, state);
        if (region) cityWrap.append(region, `, `);
        // cityWrap.append(country);
        cityWrap.append("<br>", note);
        //wrap
        let location = $("<div>");
        location.addClass("venue");
        location.append(venueLink, cityWrap);

        //Get Facebook RSVP and put it in a link
        let fbText;
        let fbEvent;
        if (event.FacebookEventId)
          (fbText =
            '<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-square" class="svg-inline--fa fa-facebook-square fa-w-14" role="img" aria-label="Facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path></svg> RSVP'),
            (fbEvent =
              "https://www.facebook.com/events/" + event.FacebookEventId);
        let fbLink = $("<a role='button'>");
        fbLink.attr("href", fbEvent);
        fbLink.attr("target", "_blank");
        fbLink.append(fbText);

        // if facebook is included in this tour widget, add fbButton to listItem.append below
        let fbButton = $('<div class="RSVP">');
        if (event.FacebookEventId) fbButton.append(fbLink);

        // Get the Ticket Details and put it in a <button>
        let ticketText;
        let ticketEvent;

        if (event.ExternalTicketUrl)
          (ticketText = "Buy Tickets"),
            (ticketEvent = "https://tour.sarahmclachlan.com/");

        let ticketLink = $("<a role='button'>");

        ticketLink.attr("href", ticketEvent);
        ticketLink.attr("target", "_blank");
        ticketLink.append(ticketText);

        // if ticket purchase button is included in this tour widget, add ticketButton to listItem.append below
        let ticketButton = $('<div class="buyTicket">');
        if (event.ExternalTicketUrl) ticketButton.append(ticketLink);

        let listItem = $("<li>");
        listItem.addClass("tourDate");
        listItem.append(dateWrap, location, fbButton, ticketButton);

        //NOTE listItem is an object so I accessed the property outerHTML to get the <li> html in string form
        let listItemHtml = listItem[0].outerHTML;
        items += listItemHtml;
      }
    } else {
      $("#noDates").show();
    }
    addToPage(items);
  }

  function addToPage(items) {
    tourContainer.append(items);
  }

  function formatDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let day = date.getDate();
    let dayString = ("0" + day).slice(-2);
    let monthIndex = date.getMonth();
    //let year = date.getFullYear();

    return (
      '<span class="month">' +
      monthNames[monthIndex] +
      " </span>" +
      '<span class="day">' +
      dayString +
      "</span>"
    ); // + '<span class="year">' + year + '</span>';
  }
};

//Youtube Video Embed
app.video = function () {
  var div,
    n,
    v = document.getElementsByClassName("youtube-player-single");
  for (n = 0; n < v.length; n++) {
    //this version is wrapped in a button as it plays the video inline
    div = document.createElement("button");
    div.setAttribute("data-id", v[n].dataset.id);
    div.setAttribute("data-title", v[n].dataset.title);
    div.setAttribute("title", v[n].dataset.title);
    div.setAttribute("class", "video-player-button");
    div.innerHTML = videoThumb(v[n].dataset.id);
    div.onclick = videoIframe;
    v[n].appendChild(div);
  }

  function videoThumb(id) {
    let imgAlt = v[n].dataset.title;
    var thumb = `<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="${imgAlt}" width="480" height="360" loading="lazy">`,
      play = '<div class="play"></div>';
    return thumb.replace("ID", id) + play;
  }

  function videoIframe() {
    var iframe = document.createElement("iframe");
    var embed = "https://www.youtube.com/embed/ID?autoplay=1&mute=1";
    iframe.setAttribute("src", embed.replace("ID", this.dataset.id));
    iframe.setAttribute("title", this.dataset.title);
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "1");
    iframe.setAttribute("width", "955");
    iframe.setAttribute("height", "536");
    iframe.setAttribute("loading", "lazy");
    this.parentNode.replaceChild(iframe, this);
  }
};

// variation of video function for landing pages
app.videopreview = function () {
  var div,
    n,
    v = document.getElementsByClassName("youtube-player-preview");
  for (n = 0; n < v.length; n++) {
    //this version is wrapped in a div not button as it navigates to video single page and does not play video inline
    div = document.createElement("div");
    div.setAttribute("data-id", v[n].dataset.id);
    div.setAttribute("data-title", v[n].dataset.title);
    div.innerHTML = videoThumb(v[n].dataset.id);
    v[n].appendChild(div);
  }

  function videoThumb(id) {
    let imgAlt = v[n].dataset.title;
    var thumb = `<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="${imgAlt}" width="480" height="360" loading="lazy">`;
    return thumb.replace("ID", id);
  }
};

//slider and modal as used on a single gallery page. Does not autoadvance.
app.galleryModal = function () {
  const imageModal = document.querySelector(".gallery-modal");
  const closeButton = document.querySelector(".gallery-modal-close");

  let previousActiveElement;

  if (imageModal) {
    $(".previewImage").focus(function () {
      if ($(".gallery-modal").hasClass("modal-showing")) {
        closeImageModal();
      }
    });

    let slideIndex = null;

    $(".previewImage").on("click", function () {
      slideIndex = $(this).data("index");
      showSlides((slideIndex += 1));
      previousActiveElement = document.activeElement;
      imageModal.classList.add("modal-showing");
      $("body").addClass("body-with-open-modal");
      $(imageModal).attr("aria-hidden", "false");
      closeButton.focus();
      return previousActiveElement;
    });

    function plusSlides(n) {
      showSlides((slideIndex += n));
    }

    $(".slider-prev").click(function () {
      plusSlides(-1);
    });
    $(".slider-next").click(function () {
      plusSlides(1);
    });

    function showSlides(n) {
      let i;
      let x = document.getElementsByClassName("one-modal-img");
      if (n > x.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = x.length;
      }
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      x[slideIndex - 1].style.display = "block";
    }

    closeButton.addEventListener("click", closeImageModal);

    //ESCAPE BUTTON CLOSES MODAL
    $(document).keydown(function (event) {
      if (event.keyCode == 27) {
        closeImageModal();
      }
    });

    function closeImageModal() {
      imageModal.classList.remove("modal-showing");
      $("body").removeClass("body-with-open-modal");
      $(imageModal).attr("aria-hidden", "true");
      previousActiveElement.focus();
    }
  }
};

//Dropdowns
app.dropdown = function () {
  $(".dropdownContent").hide();
  $(".dropdown").click(function () {
    $(this).siblings(".dropdownContent").slideToggle();
    $(this).find(".caret").toggleClass("fa-caret-up fa-caret-down");
  });
};

// Mobile Hamburger Menu
app.hamburger = function () {
  //then toggle the nav

  $(".nav-icon").click(function () {
    let isNavOpen = $(".mobile-menu-open");

    if (isNavOpen.length > 0) {
      $(".nav-icon").attr("aria-expanded", "false");
      $("body").removeClass("mobile-menu-open");
      $(this).removeClass("open");
    } else {
      $(".nav-icon").attr("aria-expanded", "true");
      $("body").addClass("mobile-menu-open");
      $(this).addClass("open");
    }
  });
};

$(document).ready(function () {
  //call all the app functions
  app.init();
  if (OCC.Membership.IsLoggedIn()) {
    toggleMembershipVisibility(true, OCC.Membership.UserName());
  } else {
      toggleMembershipVisibility(false);
  }
});

